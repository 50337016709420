var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "scale-wrap", attrs: { id: "fullDom" } },
    [
      _c("div", { staticClass: "flex-column bg" }, [
        _c("div", { staticClass: "flex-column__top" }, [
          _c(
            "div",
            {
              staticClass: "left",
              class: `${_vm.isFullscreen ? "font24" : "font16"}`,
            },
            [_vm._v(_vm._s(_vm.currentTime))]
          ),
          _c(
            "div",
            {
              staticClass: "main",
              class: `${_vm.isFullscreen ? "font24" : "font16"}`,
              on: { click: _vm.changeFullscreen },
            },
            [_vm._v("巨莱昂坐席监控综合数据")]
          ),
          _c("div", { staticClass: "right" }),
        ]),
        _c("div", { staticClass: "flex-column__main" }, [
          _c("div", { staticClass: "main1" }, [
            _c("div", { staticClass: "agent--status__container" }, [
              _vm._m(0),
              _c("div", { staticClass: "agent--status__container__content" }, [
                _c(
                  "div",
                  { staticClass: "content content1 scrollbar" },
                  _vm._l(_vm.userList, function (user) {
                    return _c("div", { key: user.userId }, [
                      _c(
                        "span",
                        { style: `color: ${user.b_warning == 1 ? "red" : ""}` },
                        [_vm._v(_vm._s(user.nickName))]
                      ),
                      _c("span", {
                        class: _vm._f("getAgentStatusClass")(user.p_status),
                      }),
                    ])
                  }),
                  0
                ),
                _c("div", { staticClass: "content content2" }, [
                  _c("div", [
                    _c("div", [
                      _c("span", { staticClass: "circle-green" }),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("getPercent")(
                              _vm.userStatus.free / _vm.userList.length
                            )
                          )
                        ),
                      ]),
                      _c("span", [_vm._v(_vm._s(_vm.userStatus.free))]),
                    ]),
                  ]),
                  _c("div", [
                    _c("div", [
                      _c("span", { staticClass: "circle-red" }),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("getPercent")(
                              _vm.userStatus.busy / _vm.userList.length
                            )
                          )
                        ),
                      ]),
                      _c("span", [_vm._v(_vm._s(_vm.userStatus.busy))]),
                    ]),
                  ]),
                  _c("div", [
                    _c("div", [
                      _c("span", { staticClass: "circle-orange" }),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("getPercent")(
                              _vm.userStatus.record / _vm.userList.length
                            )
                          )
                        ),
                      ]),
                      _c("span", [_vm._v(_vm._s(_vm.userStatus.record))]),
                    ]),
                  ]),
                  _c("div", [
                    _c("div", [
                      _c("span", { staticClass: "circle-blue" }),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("getPercent")(
                              _vm.userStatus.collect / _vm.userList.length
                            )
                          )
                        ),
                      ]),
                      _c("span", [_vm._v(_vm._s(_vm.userStatus.collect))]),
                    ]),
                  ]),
                  _c("div", [
                    _c("div", [
                      _c("span", { staticClass: "circle-gray" }),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("getPercent")(
                              _vm.userStatus.unLogin / _vm.userList.length
                            )
                          )
                        ),
                      ]),
                      _c("span", [_vm._v(_vm._s(_vm.userStatus.unLogin))]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { ref: "callIn", staticClass: "callIn__container" }, [
              _vm._m(1),
              _c("div", {
                staticClass: "callIn__content",
                attrs: { id: "callInChart" },
              }),
            ]),
            _c("div", { ref: "callOut", staticClass: "callOut__container" }, [
              _vm._m(2),
              _c("div", {
                staticClass: "callOut__content",
                attrs: { id: "callOutChart" },
              }),
            ]),
          ]),
          _c("div", { staticClass: "main2" }, [
            _c("div", {
              staticClass: "callMap__container",
              staticStyle: {
                width: "100%",
                height: "100%",
                "background-image": "none !important",
                "background-color": "#1e6fff00 !important",
                cursor: "pointer",
              },
              attrs: { id: "mapContainer" },
            }),
            _c(
              "div",
              { ref: "callHistoryChart", staticClass: "history__container" },
              [
                _c("div", { staticClass: "title" }, [
                  _c("span", { on: { click: _vm.openHistoryDialogHandle } }, [
                    _vm._v("通话详情"),
                  ]),
                ]),
                _c("div", {
                  staticClass: "history__content",
                  staticStyle: { width: "100%", height: "100%" },
                  attrs: { id: "callHistoryChart" },
                }),
              ]
            ),
          ]),
          _c("div", { staticClass: "main3" }, [
            _c("div", { staticClass: "total-count__container" }, [
              _vm._m(3),
              _c("div", { staticClass: "total-count__content" }, [
                _c("div", { staticClass: "total-count__item" }, [
                  _c(
                    "h1",
                    { staticStyle: { color: "#fff", "margin-bottom": "10px" } },
                    [_vm._v("总话务量")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "el-progress custom-progress-text el-progress--circle",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "el-progress-circle",
                          staticStyle: { height: "80px", width: "80px" },
                        },
                        [
                          _c("svg", { attrs: { viewBox: "0 0 100 100" } }, [
                            _c("path", {
                              staticClass: "el-progress-circle__track",
                              staticStyle: {
                                "stroke-dasharray": "270.177px, 270.177px",
                                "stroke-dashoffset": "0px",
                              },
                              attrs: {
                                d: " M 50 50 m 0 -43 a 43 43 0 1 1 0 86 a 43 43 0 1 1 0 -86",
                                stroke: "#ebeef5",
                                "stroke-width": "12.5",
                                fill: "none",
                              },
                            }),
                            _c("path", {
                              staticClass: "el-progress-circle__path",
                              staticStyle: {
                                "stroke-dasharray": "270.177px, 270.177px",
                                "stroke-dashoffset": "0px",
                                transition:
                                  "stroke-dasharray 0.6s, stroke 0.6s",
                              },
                              attrs: {
                                d: " M 50 50 m 0 -43 a 43 43 0 1 1 0 86 a 43 43 0 1 1 0 -86",
                                stroke: "#0000ff",
                                fill: "none",
                                "stroke-linecap": "round",
                                "stroke-width": "12.5",
                              },
                            }),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "el-progress__text",
                          staticStyle: { "font-size": "12px", color: "white" },
                        },
                        [_vm._v(_vm._s(_vm.countTotalData.answer_total))]
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "total-count__item" }, [
                  _c(
                    "h3",
                    { staticStyle: { color: "#fff", "margin-bottom": "10px" } },
                    [_vm._v("呼入话务量")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "el-progress custom-progress-text el-progress--circle",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "el-progress-circle",
                          staticStyle: { height: "80px", width: "80px" },
                        },
                        [
                          _c("svg", { attrs: { viewBox: "0 0 100 100" } }, [
                            _c("path", {
                              staticClass: "el-progress-circle__track",
                              staticStyle: {
                                "stroke-dasharray": "270.177px, 270.177px",
                                "stroke-dashoffset": "0px",
                              },
                              attrs: {
                                d: " M 50 50 m 0 -43 a 43 43 0 1 1 0 86 a 43 43 0 1 1 0 -86",
                                stroke: "#ebeef5",
                                "stroke-width": "12.5",
                                fill: "none",
                              },
                            }),
                            _c("path", {
                              staticClass: "el-progress-circle__path",
                              staticStyle: {
                                "stroke-dasharray": "270.177px, 270.177px",
                                "stroke-dashoffset": "0px",
                                transition:
                                  "stroke-dasharray 0.6s, stroke 0.6s",
                              },
                              attrs: {
                                d: " M 50 50 m 0 -43 a 43 43 0 1 1 0 86 a 43 43 0 1 1 0 -86",
                                stroke: "#00ff00",
                                fill: "none",
                                "stroke-linecap": "round",
                                "stroke-width": "12.5",
                              },
                            }),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "el-progress__text",
                          staticStyle: { "font-size": "12px", color: "white" },
                        },
                        [_vm._v(_vm._s(_vm.countTotalData.answer_in_total))]
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "total-count__item" }, [
                  _c(
                    "h3",
                    { staticStyle: { color: "#fff", "margin-bottom": "10px" } },
                    [_vm._v("呼出话务量")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "el-progress custom-progress-text el-progress--circle",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "el-progress-circle",
                          staticStyle: { height: "80px", width: "80px" },
                        },
                        [
                          _c("svg", { attrs: { viewBox: "0 0 100 100" } }, [
                            _c("path", {
                              staticClass: "el-progress-circle__track",
                              staticStyle: {
                                "stroke-dasharray": "270.177px, 270.177px",
                                "stroke-dashoffset": "0px",
                              },
                              attrs: {
                                d: " M 50 50 m 0 -43 a 43 43 0 1 1 0 86 a 43 43 0 1 1 0 -86",
                                stroke: "#ebeef5",
                                "stroke-width": "12.5",
                                fill: "none",
                              },
                            }),
                            _c("path", {
                              staticClass: "el-progress-circle__path",
                              staticStyle: {
                                "stroke-dasharray": "270.177px, 270.177px",
                                "stroke-dashoffset": "0px",
                                transition:
                                  "stroke-dasharray 0.6s, stroke 0.6s",
                              },
                              attrs: {
                                d: " M 50 50 m 0 -43 a 43 43 0 1 1 0 86 a 43 43 0 1 1 0 -86",
                                stroke: "#ffff00",
                                fill: "none",
                                "stroke-linecap": "round",
                                "stroke-width": "12.5",
                              },
                            }),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "el-progress__text",
                          staticStyle: { "font-size": "12px", color: "white" },
                        },
                        [_vm._v(_vm._s(_vm.countTotalData.answer_out_total))]
                      ),
                    ]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "total-count__content" }, [
                _c("div", { staticClass: "total-count__item" }, [
                  _c(
                    "h3",
                    { staticStyle: { color: "#fff", "margin-bottom": "10px" } },
                    [_vm._v("用户放弃数")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "el-progress custom-progress-text el-progress--circle",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "el-progress-circle",
                          staticStyle: { height: "80px", width: "80px" },
                        },
                        [
                          _c("svg", { attrs: { viewBox: "0 0 100 100" } }, [
                            _c("path", {
                              staticClass: "el-progress-circle__track",
                              staticStyle: {
                                "stroke-dasharray": "270.177px, 270.177px",
                                "stroke-dashoffset": "0px",
                              },
                              attrs: {
                                d: " M 50 50 m 0 -43 a 43 43 0 1 1 0 86 a 43 43 0 1 1 0 -86",
                                stroke: "#ebeef5",
                                "stroke-width": "12.5",
                                fill: "none",
                              },
                            }),
                            _c("path", {
                              staticClass: "el-progress-circle__path",
                              staticStyle: {
                                "stroke-dasharray": "270.177px, 270.177px",
                                "stroke-dashoffset": "0px",
                                transition:
                                  "stroke-dasharray 0.6s, stroke 0.6s",
                              },
                              attrs: {
                                d: " M 50 50 m 0 -43 a 43 43 0 1 1 0 86 a 43 43 0 1 1 0 -86",
                                stroke: "red",
                                fill: "none",
                                "stroke-linecap": "round",
                                "stroke-width": "12.5",
                              },
                            }),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "el-progress__text",
                          staticStyle: { "font-size": "12px", color: "white" },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.countTotalData.answer_queue_lost_count)
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "total-count__item" }, [
                  _c(
                    "h3",
                    { staticStyle: { color: "#fff", "margin-bottom": "10px" } },
                    [_vm._v("15s内接听数")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "el-progress custom-progress-text el-progress--circle",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "el-progress-circle",
                          staticStyle: { height: "80px", width: "80px" },
                        },
                        [
                          _c("svg", { attrs: { viewBox: "0 0 100 100" } }, [
                            _c("path", {
                              staticClass: "el-progress-circle__track",
                              staticStyle: {
                                "stroke-dasharray": "270.177px, 270.177px",
                                "stroke-dashoffset": "0px",
                              },
                              attrs: {
                                d: " M 50 50 m 0 -43 a 43 43 0 1 1 0 86 a 43 43 0 1 1 0 -86",
                                stroke: "#ebeef5",
                                "stroke-width": "12.5",
                                fill: "none",
                              },
                            }),
                            _c("path", {
                              staticClass: "el-progress-circle__path",
                              staticStyle: {
                                "stroke-dasharray": "270.177px, 270.177px",
                                "stroke-dashoffset": "0px",
                                transition:
                                  "stroke-dasharray 0.6s, stroke 0.6s",
                              },
                              attrs: {
                                d: " M 50 50 m 0 -43 a 43 43 0 1 1 0 86 a 43 43 0 1 1 0 -86",
                                stroke: "#00ff00",
                                fill: "none",
                                "stroke-linecap": "round",
                                "stroke-width": "12.5",
                              },
                            }),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "el-progress__text",
                          staticStyle: { "font-size": "12px", color: "white" },
                        },
                        [_vm._v(_vm._s(_vm.countTotalData.answer_15_count))]
                      ),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "total-count__item" },
                  [
                    _c(
                      "h3",
                      {
                        staticStyle: { color: "#fff", "margin-bottom": "10px" },
                      },
                      [_vm._v("15s内接听率")]
                    ),
                    _c("el-progress", {
                      staticClass: "custom-progress-text",
                      attrs: {
                        type: "circle",
                        percentage: _vm.countTotalData.answer_15_percent,
                        "text-color": "white",
                        width: 80,
                        "stroke-width": 10,
                        color: _vm.customColors,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c(
              "div",
              { ref: "callTotalPie", staticClass: "total-count__container" },
              [_vm._m(4), _vm._m(5)]
            ),
            _c(
              "div",
              { ref: "recordDataChart", staticClass: "total-count__container" },
              [
                _vm._m(6),
                _c("div", {
                  staticClass: "record__content",
                  attrs: { id: "recordDataChart" },
                }),
              ]
            ),
          ]),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "通话详情",
            visible: _vm.historyDialog,
            width: "25%",
            top: "35vh",
            "before-close": _vm.historyDialogHandleClose,
            "modal-append-to-body": false,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.historyDialog = $event
            },
          },
        },
        [
          _c(
            "dc-search-panel",
            {
              staticStyle: { border: "none" },
              attrs: { params: _vm.historyQueryPara },
              on: { search: _vm.doSearch },
            },
            [
              _c(
                "dc-form-item",
                { attrs: { label: "时间类型" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.historyQueryPara.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.historyQueryPara, "type", $$v)
                        },
                        expression: "historyQueryPara.type",
                      },
                    },
                    _vm._l(_vm.historyType, function (item) {
                      return _c(
                        "el-radio",
                        { key: item.value, attrs: { label: item.value } },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "dc-form-item",
                { attrs: { label: "统计类型" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.historyQueryPara.typeCount,
                        callback: function ($$v) {
                          _vm.$set(_vm.historyQueryPara, "typeCount", $$v)
                        },
                        expression: "historyQueryPara.typeCount",
                      },
                    },
                    _vm._l(_vm.historyTypeCount, function (item) {
                      return _c(
                        "el-radio",
                        { key: item.value, attrs: { label: item.value } },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "dc-form-item",
                { attrs: { label: "开始时间" } },
                [
                  _vm.historyQueryPara.type == 1
                    ? _c("el-date-picker", {
                        attrs: {
                          type: "datetime",
                          placeholder: "选择日期",
                          format: "yyyy-MM-dd HH",
                          "data-format": "yyyy-MM-dd HH",
                          "value-format": "yyyy-MM-dd HH",
                          "popper-class": "customer-date-picker",
                        },
                        model: {
                          value: _vm.historyQueryPara.startDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.historyQueryPara, "startDate", $$v)
                          },
                          expression: "historyQueryPara.startDate",
                        },
                      })
                    : _vm._e(),
                  _vm.historyQueryPara.type == 2
                    ? _c("el-date-picker", {
                        attrs: {
                          format: "yyyy-MM-dd",
                          "data-format": "yyyy-MM-dd",
                          "value-format": "yyyy-MM-dd",
                          "popper-class": "customer-date-picker",
                        },
                        model: {
                          value: _vm.historyQueryPara.startDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.historyQueryPara, "startDate", $$v)
                          },
                          expression: "historyQueryPara.startDate",
                        },
                      })
                    : _vm._e(),
                  _vm.historyQueryPara.type == 3
                    ? _c("el-date-picker", {
                        attrs: {
                          format: "yyyy-MM",
                          "data-format": "yyyy-MM",
                          "value-format": "yyyy-MM",
                          "popper-class": "customer-date-picker",
                        },
                        model: {
                          value: _vm.historyQueryPara.startDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.historyQueryPara, "startDate", $$v)
                          },
                          expression: "historyQueryPara.startDate",
                        },
                      })
                    : _vm._e(),
                  _vm.historyQueryPara.type == 4
                    ? _c("el-date-picker", {
                        attrs: {
                          format: "yyyy",
                          "data-format": "yyyy",
                          "value-format": "yyyy",
                          "popper-class": "customer-date-picker",
                        },
                        model: {
                          value: _vm.historyQueryPara.startDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.historyQueryPara, "startDate", $$v)
                          },
                          expression: "historyQueryPara.startDate",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "dc-form-item",
                { attrs: { label: "结束时间" } },
                [
                  _vm.historyQueryPara.type == 1
                    ? _c("el-date-picker", {
                        attrs: {
                          type: "datetime",
                          placeholder: "选择日期",
                          format: "yyyy-MM-dd HH",
                          "data-format": "yyyy-MM-dd HH",
                          "value-format": "yyyy-MM-dd HH",
                          "popper-class": "customer-date-picker",
                        },
                        model: {
                          value: _vm.historyQueryPara.endDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.historyQueryPara, "endDate", $$v)
                          },
                          expression: "historyQueryPara.endDate",
                        },
                      })
                    : _vm._e(),
                  _vm.historyQueryPara.type == 2
                    ? _c("el-date-picker", {
                        attrs: {
                          format: "yyyy-MM-dd",
                          "data-format": "yyyy-MM-dd",
                          "value-format": "yyyy-MM-dd",
                          "popper-class": "customer-date-picker",
                        },
                        model: {
                          value: _vm.historyQueryPara.endDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.historyQueryPara, "endDate", $$v)
                          },
                          expression: "historyQueryPara.endDate",
                        },
                      })
                    : _vm._e(),
                  _vm.historyQueryPara.type == 3
                    ? _c("el-date-picker", {
                        attrs: {
                          format: "yyyy-MM",
                          "data-format": "yyyy-MM",
                          "value-format": "yyyy-MM",
                          "popper-class": "customer-date-picker",
                        },
                        model: {
                          value: _vm.historyQueryPara.endDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.historyQueryPara, "endDate", $$v)
                          },
                          expression: "historyQueryPara.endDate",
                        },
                      })
                    : _vm._e(),
                  _vm.historyQueryPara.type == 4
                    ? _c("el-date-picker", {
                        attrs: {
                          format: "yyyy",
                          "data-format": "yyyy",
                          "value-format": "yyyy",
                          "popper-class": "customer-date-picker",
                        },
                        model: {
                          value: _vm.historyQueryPara.endDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.historyQueryPara, "endDate", $$v)
                          },
                          expression: "historyQueryPara.endDate",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.historyDialogHandleClose } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.getHistoryData },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("当前坐席状态")]),
      _c("span", { staticClass: "circle-green" }),
      _c("span", [_vm._v("空闲")]),
      _vm._v(" "),
      _c("span", { staticClass: "circle-red" }),
      _c("span", [_vm._v("忙碌")]),
      _vm._v(" "),
      _c("span", { staticClass: "circle-orange" }),
      _c("span", [_vm._v("通话中")]),
      _vm._v(" "),
      _c("span", { staticClass: "circle-blue" }),
      _c("span", [_vm._v("整理")]),
      _c("span", { staticClass: "circle-gray" }),
      _c("span", [_vm._v("未登录")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("今日逐小时呼入通话详情")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("今日逐小时呼出通话详情")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("今日话务量数据概览")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("话务量数据概览")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "callTotalPie" }, [
      _c("div", { attrs: { id: "callInAnswerPercentChart" } }),
      _c("div", { attrs: { id: "callOutAnswerPercentChart" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("话务量排行")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }