<template>
  <div id="fullDom" class="scale-wrap">
    <div class="flex-column bg">
      <div class="flex-column__top">
        <div class="left" :class="`${isFullscreen ? 'font24' : 'font16'}`">{{ currentTime }}</div>
        <div class="main" :class="`${isFullscreen ? 'font24' : 'font16'}`" @click="changeFullscreen">巨莱昂坐席监控综合数据</div>
        <div class="right"></div>
      </div>
      <div class="flex-column__main">
        <div class="main1">
          <div class="agent--status__container">
            <div class="title">
              <span>当前坐席状态</span>
              <span class="circle-green"></span><span>空闲</span> <span class="circle-red"></span><span>示忙</span> <span class="circle-orange"></span><span>通话中</span> <span class="circle-blue"></span><span>整理</span><span class="circle-gray"></span><span>未登录</span>
            </div>
            <div class="agent--status__container__content">
              <div class="content content1 scrollbar">
                <div v-for="user in userList" :key="user.userId">
                  <span :style="`color: ${user.b_warning == 1 ? 'red' : ''}`">{{ user.nickName }}</span>
                  <span :class="user.p_status | getAgentStatusClass"></span>
                </div>
              </div>
              <div class="content content2">
                <div>
                  <div>
                    <span class="circle-green"></span>
                    <span>{{ (userStatus.free / userList.length) | getPercent }}</span>
                    <span>{{ userStatus.free }}</span>
                  </div>
                </div>
                <div>
                  <div>
                    <span class="circle-red"></span>
                    <span>{{ (userStatus.busy / userList.length) | getPercent }}</span>
                    <span>{{ userStatus.busy }}</span>
                  </div>
                </div>
                <div>
                  <div>
                    <span class="circle-orange"></span>
                    <span>{{ (userStatus.record / userList.length) | getPercent }}</span>
                    <span>{{ userStatus.record }}</span>
                  </div>
                </div>
                <div>
                  <div>
                    <span class="circle-blue"></span>
                    <span>{{ (userStatus.collect / userList.length) | getPercent }}</span>
                    <span>{{ userStatus.collect }}</span>
                  </div>
                </div>
                <div>
                  <div>
                    <span class="circle-gray"></span>
                    <span>{{ (userStatus.unLogin / userList.length) | getPercent }}</span>
                    <span>{{ userStatus.unLogin }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div ref="callIn" class="callIn__container">
            <div class="title">
              <span>今日逐小时呼入通话详情</span>
            </div>
            <div id="callInChart" class="callIn__content"></div>
          </div>
          <div ref="callOut" class="callOut__container">
            <div class="title">
              <span>今日逐小时呼出通话详情</span>
            </div>
            <div id="callOutChart" class="callOut__content"></div>
          </div>
        </div>
        <div class="main2">
          <div id="mapContainer" class="callMap__container" style="width: 100%; height: 100%; background-image: none !important; background-color: #1e6fff00 !important; cursor: pointer"></div>
          <div ref="callHistoryChart" class="history__container">
            <div class="title">
              <span @click="openHistoryDialogHandle">通话详情</span>
            </div>
            <div id="callHistoryChart" class="history__content" style="width: 100%; height: 100%"></div>
          </div>
        </div>
        <div class="main3">
          <div class="total-count__container">
            <div class="title">
              <span>今日话务量数据概览</span>
            </div>
            <div class="total-count__content">
              <div class="total-count__item">
                <h1 style="color: #fff; margin-bottom: 10px">总话务量</h1>
                <div class="el-progress custom-progress-text el-progress--circle">
                  <div class="el-progress-circle" style="height: 80px; width: 80px">
                    <svg viewBox="0 0 100 100">
                      <path d=" M 50 50 m 0 -43 a 43 43 0 1 1 0 86 a 43 43 0 1 1 0 -86" stroke="#ebeef5" stroke-width="12.5" fill="none" class="el-progress-circle__track" style="stroke-dasharray: 270.177px, 270.177px; stroke-dashoffset: 0px"></path>
                      <path d=" M 50 50 m 0 -43 a 43 43 0 1 1 0 86 a 43 43 0 1 1 0 -86" stroke="#0000ff" fill="none" stroke-linecap="round" stroke-width="12.5" class="el-progress-circle__path" style="stroke-dasharray: 270.177px, 270.177px; stroke-dashoffset: 0px; transition: stroke-dasharray 0.6s, stroke 0.6s"></path>
                    </svg>
                  </div>
                  <div class="el-progress__text" style="font-size: 12px; color: white">{{ countTotalData.answer_total }}</div>
                </div>
              </div>
              <div class="total-count__item">
                <h3 style="color: #fff; margin-bottom: 10px">呼入话务量</h3>
                <div class="el-progress custom-progress-text el-progress--circle">
                  <div class="el-progress-circle" style="height: 80px; width: 80px">
                    <svg viewBox="0 0 100 100">
                      <path d=" M 50 50 m 0 -43 a 43 43 0 1 1 0 86 a 43 43 0 1 1 0 -86" stroke="#ebeef5" stroke-width="12.5" fill="none" class="el-progress-circle__track" style="stroke-dasharray: 270.177px, 270.177px; stroke-dashoffset: 0px"></path>
                      <path d=" M 50 50 m 0 -43 a 43 43 0 1 1 0 86 a 43 43 0 1 1 0 -86" stroke="#00ff00" fill="none" stroke-linecap="round" stroke-width="12.5" class="el-progress-circle__path" style="stroke-dasharray: 270.177px, 270.177px; stroke-dashoffset: 0px; transition: stroke-dasharray 0.6s, stroke 0.6s"></path>
                    </svg>
                  </div>
                  <div class="el-progress__text" style="font-size: 12px; color: white">{{ countTotalData.answer_in_total }}</div>
                </div>
              </div>
              <div class="total-count__item">
                <h3 style="color: #fff; margin-bottom: 10px">呼出话务量</h3>
                <div class="el-progress custom-progress-text el-progress--circle">
                  <div class="el-progress-circle" style="height: 80px; width: 80px">
                    <svg viewBox="0 0 100 100">
                      <path d=" M 50 50 m 0 -43 a 43 43 0 1 1 0 86 a 43 43 0 1 1 0 -86" stroke="#ebeef5" stroke-width="12.5" fill="none" class="el-progress-circle__track" style="stroke-dasharray: 270.177px, 270.177px; stroke-dashoffset: 0px"></path>
                      <path d=" M 50 50 m 0 -43 a 43 43 0 1 1 0 86 a 43 43 0 1 1 0 -86" stroke="#ffff00" fill="none" stroke-linecap="round" stroke-width="12.5" class="el-progress-circle__path" style="stroke-dasharray: 270.177px, 270.177px; stroke-dashoffset: 0px; transition: stroke-dasharray 0.6s, stroke 0.6s"></path>
                    </svg>
                  </div>
                  <div class="el-progress__text" style="font-size: 12px; color: white">{{ countTotalData.answer_out_total }}</div>
                </div>
              </div>
            </div>
            <div class="total-count__content">
              <div class="total-count__item">
                <h3 style="color: #fff; margin-bottom: 10px">排队用户放弃数</h3>
                <div class="el-progress custom-progress-text el-progress--circle">
                  <div class="el-progress-circle" style="height: 80px; width: 80px">
                    <svg viewBox="0 0 100 100">
                      <path d=" M 50 50 m 0 -43 a 43 43 0 1 1 0 86 a 43 43 0 1 1 0 -86" stroke="#ebeef5" stroke-width="12.5" fill="none" class="el-progress-circle__track" style="stroke-dasharray: 270.177px, 270.177px; stroke-dashoffset: 0px"></path>
                      <path d=" M 50 50 m 0 -43 a 43 43 0 1 1 0 86 a 43 43 0 1 1 0 -86" stroke="red" fill="none" stroke-linecap="round" stroke-width="12.5" class="el-progress-circle__path" style="stroke-dasharray: 270.177px, 270.177px; stroke-dashoffset: 0px; transition: stroke-dasharray 0.6s, stroke 0.6s"></path>
                    </svg>
                  </div>
                  <div class="el-progress__text" style="font-size: 12px; color: white">{{ countTotalData.answer_queue_lost_count }}</div>
                </div>
              </div>
              <div class="total-count__item">
                <h3 style="color: #fff; margin-bottom: 10px">15s内接听数</h3>
                <div class="el-progress custom-progress-text el-progress--circle">
                  <div class="el-progress-circle" style="height: 80px; width: 80px">
                    <svg viewBox="0 0 100 100">
                      <path d=" M 50 50 m 0 -43 a 43 43 0 1 1 0 86 a 43 43 0 1 1 0 -86" stroke="#ebeef5" stroke-width="12.5" fill="none" class="el-progress-circle__track" style="stroke-dasharray: 270.177px, 270.177px; stroke-dashoffset: 0px"></path>
                      <path d=" M 50 50 m 0 -43 a 43 43 0 1 1 0 86 a 43 43 0 1 1 0 -86" stroke="#00ff00" fill="none" stroke-linecap="round" stroke-width="12.5" class="el-progress-circle__path" style="stroke-dasharray: 270.177px, 270.177px; stroke-dashoffset: 0px; transition: stroke-dasharray 0.6s, stroke 0.6s"></path>
                    </svg>
                  </div>
                  <div class="el-progress__text" style="font-size: 12px; color: white">{{ countTotalData.answer_15_count }}</div>
                </div>
              </div>
              <div class="total-count__item">
                <h3 style="color: #fff; margin-bottom: 10px">15s内接听率</h3>
                <el-progress type="circle" :percentage="countTotalData.answer_15_percent" text-color="white" :width="80" :stroke-width="10" class="custom-progress-text" :color="customColors"></el-progress>
              </div>
            </div>
          </div>
          <div ref="callTotalPie" class="total-count__container">
            <div class="title">
              <span>话务量数据概览</span>
            </div>
            <div class="callTotalPie">
              <div id="callInAnswerPercentChart"></div>
              <div id="callOutAnswerPercentChart"></div>
            </div>
          </div>
          <div ref="recordDataChart" class="total-count__container">
            <div class="title">
              <span>话务量排行</span>
            </div>
            <div id="recordDataChart" class="record__content"></div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="通话详情" :visible.sync="historyDialog" width="25%" top="35vh" :before-close="historyDialogHandleClose" :modal-append-to-body="false" :show-close="false">
      <dc-search-panel :params="historyQueryPara" style="border: none" @search="doSearch">
        <dc-form-item label="时间类型">
          <el-radio-group v-model="historyQueryPara.type">
            <el-radio v-for="item in historyType" :key="item.value" :label="item.value">{{ item.name }}</el-radio>
          </el-radio-group>
        </dc-form-item>
        <dc-form-item label="统计类型">
          <el-radio-group v-model="historyQueryPara.typeCount">
            <el-radio v-for="item in historyTypeCount" :key="item.value" :label="item.value">{{ item.name }}</el-radio>
          </el-radio-group>
        </dc-form-item>
        <dc-form-item label="开始时间">
          <el-date-picker v-if="historyQueryPara.type == 1" v-model="historyQueryPara.startDate" type="datetime" placeholder="选择日期" format="yyyy-MM-dd HH" data-format="yyyy-MM-dd HH" value-format="yyyy-MM-dd HH" popper-class="customer-date-picker"></el-date-picker>
          <el-date-picker v-if="historyQueryPara.type == 2" v-model="historyQueryPara.startDate" format="yyyy-MM-dd" data-format="yyyy-MM-dd" value-format="yyyy-MM-dd" popper-class="customer-date-picker"></el-date-picker>
          <el-date-picker v-if="historyQueryPara.type == 3" v-model="historyQueryPara.startDate" format="yyyy-MM" data-format="yyyy-MM" value-format="yyyy-MM" popper-class="customer-date-picker"></el-date-picker>
          <el-date-picker v-if="historyQueryPara.type == 4" v-model="historyQueryPara.startDate" format="yyyy" data-format="yyyy" value-format="yyyy" popper-class="customer-date-picker"></el-date-picker>
        </dc-form-item>
        <dc-form-item label="结束时间">
          <el-date-picker v-if="historyQueryPara.type == 1" v-model="historyQueryPara.endDate" type="datetime" placeholder="选择日期" format="yyyy-MM-dd HH" data-format="yyyy-MM-dd HH" value-format="yyyy-MM-dd HH" popper-class="customer-date-picker"></el-date-picker>
          <el-date-picker v-if="historyQueryPara.type == 2" v-model="historyQueryPara.endDate" format="yyyy-MM-dd" data-format="yyyy-MM-dd" value-format="yyyy-MM-dd" popper-class="customer-date-picker"></el-date-picker>
          <el-date-picker v-if="historyQueryPara.type == 3" v-model="historyQueryPara.endDate" format="yyyy-MM" data-format="yyyy-MM" value-format="yyyy-MM" popper-class="customer-date-picker"></el-date-picker>
          <el-date-picker v-if="historyQueryPara.type == 4" v-model="historyQueryPara.endDate" format="yyyy" data-format="yyyy" value-format="yyyy" popper-class="customer-date-picker"></el-date-picker>
        </dc-form-item>
      </dc-search-panel>
      <span slot="footer" class="dialog-footer">
        <el-button @click="historyDialogHandleClose">取 消</el-button>
        <el-button type="primary" @click="getHistoryData">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import screenfull from "screenfull/dist/screenfull";
import lodash from "lodash";
import moment from "moment/moment";

export default {
  name: "CallMonitor",
  filters: {
    getAgentStatusClass(type) {
      if (type == -1) {
        // 未登录
        return "circle-gray";
      } else if (type == 2 || type == 7) {
        // 空闲
        return "circle-green";
      } else if (type == 3) {
        // 忙碌
        return "circle-red";
      } else if (type == 8) {
        // 整理中
        return "circle-blue";
      } else if (type == 10 || type == 11 || type == 20 || type == 4) {
        // 通话中
        return "circle-orange";
      } else {
        return "";
      }
    },
    getPercent(val) {
      if (!val) return "0%";
      return Math.round(val * 10000) / 100 + "%";
    },
  },
  props: {},
  data() {
    return {
      interval: null,
      intervalTask: null,
      intervalTaskAgent: null,
      currentTime: "",
      isFullscreen: false,
      userList: [],
      userStatus: {
        free: 0,
        busy: 0,
        record: 0,
        collect: 0,
        unLogin: 0,
      },
      callInChart: null,
      callOutChart: null,
      callInAnswerPercentChart: null,
      callOutAnswerPercentChart: null,
      callHistoryChart: null,
      recordDataChart: null,
      chartsData: [],
      countTotalData: {},
      historyData: [],
      recordData: [],
      customColors: [
        { color: "#ff0000", percentage: 20 },
        { color: "#ad1f1f", percentage: 40 },
        { color: "#d77272", percentage: 60 },
        { color: "#ff9100", percentage: 80 },
        { color: "#00ff00", percentage: 85 },
      ],
      historyDialog: false,
      historyQueryPara: {
        type: 1,
        typeCount: 1,
      },
      historyType: [
        { name: "小时", value: 1 },
        { name: "天", value: 2 },
        { name: "月", value: 3 },
        { name: "年", value: 4 },
      ],
      historyTypeCount: [
        { name: "话务量", value: 1 },
        { name: "呼入详情", value: 2 },
        { name: "呼出详情", value: 3 },
      ],
    };
  },
  mounted() {
    screenfull.on("change", this.change);
    this.updateTime();
    // 每秒更新一次时间
    this.interval = setInterval(this.updateTime, 1000);
    this.intervalTask = setInterval(() => {
      this.getChartData();
      this.getCallDataCount();
      this.getHistoryData();
      this.getRecordData();
    }, 30000);
    this.intervalTaskAgent = setInterval(() => {
      this.getAgentUserStatus();
    }, 5000);
    this.resetPara();
    this.getAgentUserStatus();
    this.getChartData();
    this.getCallDataCount();
    this.getHistoryData();
    this.getRecordData();
    window.onresize = () => {
      this.resize(this.chartsData);
      this.resizePie(this.countTotalData);
      this.resizeHistory(this.historyData);
      this.resizeRecord(this.recordData);
    };
    this.initMap();
    document.getElementById("mapContainer").style = "background: #ffffff00;";
  },
  unmounted() {
    screenfull.off("change", this.change);
  },
  beforeDestroy() {
    // 清除定时器，防止内存泄漏
    clearInterval(this.interval);
    clearInterval(this.intervalTask);
    clearInterval(this.intervalTaskAgent);
  },
  methods: {
    initMap() {
      var SOC = "CHN";
      var colors = {};
      var GDPSpeed = {
        520000: 10, //贵州
        540000: 10, //西藏
        530000: 8.5, //云南
        500000: 8.5, //重庆
        360000: 8.5, //江西
        340000: 8.0, //安徽
        510000: 7.5, //四川
        350000: 8.5, //福建
        430000: 8.0, //湖南
        420000: 7.5, //湖北
        410000: 7.5, //河南
        330000: 7.0, //浙江
        640000: 7.5, //宁夏
        650000: 7.0, //新疆
        440000: 7.0, //广东
        370000: 7.0, //山东
        450000: 7.3, //广西
        630000: 7.0, //青海
        320000: 7.0, //江苏
        140000: 6.5, //山西
        460000: 7, // 海南
        310000: 6.5, //上海
        110000: 6.5, // 北京
        130000: 6.5, // 河北
        230000: 6, // 黑龙江
        220000: 6, // 吉林
        210000: 6.5, //辽宁
        150000: 6.5, //内蒙古
        120000: 5, // 天津
        620000: 6, // 甘肃
        610000: 8.5, // 甘肃
        710000: 2.64, //台湾
        810000: 3.0, //香港
        820000: 4.7, //澳门
      };
      var getColorByDGP = function (adcode) {
        if (!colors[adcode]) {
          var gdp = GDPSpeed[adcode];
          if (!gdp) {
            colors[adcode] = "rgb(227,227,227)";
          } else {
            var r = 3;
            var g = 140;
            var b = 230;
            var a = gdp / 10;
            colors[adcode] = "rgba(" + r + "," + g + "," + b + "," + a + ")";
          }
        }
        return colors[adcode];
      };

      var disCountry = new AMap.DistrictLayer.Country({
        zIndex: 10,
        SOC: "CHN",
        depth: 1,
        styles: {
          "nation-stroke": "#ff0000",
          "coastline-stroke": "#0088ff",
          "province-stroke": "grey",
          fill: function (props) {
            return getColorByDGP(props.adcode_pro);
          },
        },
      });

      var map = new AMap.Map("mapContainer", {
        zooms: [4, 8],
        center: [106.122082, 33.719192],
        zoom: 4,
        isHotspot: false,
        defaultCursor: "pointer",
        layers: [disCountry],
        viewMode: "3D",
      });
      document.getElementById("mapContainer").style.backgroundColor = "rgba(0,0,0,0) !important";
      map.addControl(new AMap.Scale());
      map.addControl(new AMap.ToolBar({ liteStyle: true }));
      map.on("complete", function () {
        var layer = new AMap.LabelsLayer({
          // 开启标注避让，默认为开启，v1.4.15 新增属性
          collision: false,
          // 开启标注淡入动画，默认为开启，v1.4.15 新增属性
          animation: true,
        });
        // for (var i = 0; i < LabelsData.length; i++) {
        //   var labelsMarker = new AMap.LabelMarker(LabelsData[i]);
        //   layer.add(labelsMarker);
        // }
        map.add(layer);
        document.querySelector(".amap-copyright").remove();
        document.querySelector(".amap-logo").remove();
        document.querySelector(".amap-controls").remove();
      });
    },
    changeFullscreen() {
      let fullDom = document.getElementById("fullDom");
      if (fullDom) {
        screenfull.toggle(fullDom);
      }
    },
    change() {
      this.isFullscreen = screenfull.isFullscreen;
    },
    updateTime() {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0");
      const day = String(now.getDate()).padStart(2, "0");
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      const seconds = String(now.getSeconds()).padStart(2, "0");

      // 获取星期
      const weekDays = ["星期天", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
      const weekDay = weekDays[now.getDay()];

      this.currentTime = `${year}年${month}月${day}日   ${hours}:${minutes}:${seconds}   ${weekDay}`;
    },
    getAgentUserStatus() {
      let url = `${this.baseUrls.callDataCount}/getAgentUserStatus`;
      let params = {
        // roleId: [8],
      };
      this.topsAjax.get(url, params).then((res) => {
        this.userList = [];
        let unlogin = lodash.filter(res, (p) => p.p_status == -1);
        let free = lodash.filter(res, (p) => p.p_status == 2 || p.p_status == 7);
        let busy = lodash.orderBy(
          lodash.filter(res, (p) => p.p_status == 3),
          [(p) => p.b_warning],
          ["desc"]
        );
        let record = lodash.filter(res, (p) => p.p_status == 10 || p.p_status == 11 || p.p_status == 20 || p.p_status == 4);
        let collect = lodash.orderBy(
          lodash.filter(res, (p) => p.p_status == 8),
          [(p) => p.b_warning],
          ["desc"]
        );
        this.userList.push(...busy, ...collect, ...record, ...free, ...unlogin);
        this.userStatus.unLogin = unlogin.length;
        this.userStatus.free = free.length;
        this.userStatus.busy = busy.length;
        this.userStatus.record = record.length;
        this.userStatus.collect = collect.length;
        console.log(this.userStatus);
      });
    },
    getCallDataCount() {
      let url = `${this.baseUrls.callDataCount}/callDataCountByDay`;
      let params = {
        date: moment(new Date(), "YYYYMMDD").format("YYYYMMDD"),
      };
      this.topsAjax.get(url, params).then((res) => {
        this.countTotalData = res;
        this.resizePie(res);
      });
    },
    getChartData() {
      let url = `${this.baseUrls.callDataCount}/callDataCountByHour`;
      let params = {
        date: moment(new Date(), "YYYYMMDD").format("YYYYMMDD"),
      };
      this.topsAjax.get(url, params).then((res) => {
        this.chartsData = res;
        this.resize(res);
      });
    },
    getHistoryData() {
      this.historyDialog = false;
      let url = `${this.baseUrls.callDataCount}/historyCount`;
      let params = this.historyQueryPara;
      this.topsAjax.get(url, params).then((res) => {
        this.historyData = res;
        this.resizeHistory(res);
      });
    },
    getRecordData() {
      let url = `${this.baseUrls.callDataCount}/recordByUser`;
      let params = {
        startDate: moment(new Date()).format("YYYYMMDD"),
      };
      this.topsAjax.get(url, params).then((res) => {
        let result = lodash.orderBy(res, [(o) => o.call_total], ["desc"]);
        this.recordData = result;
        this.resizeRecord(result);
      });
    },
    drawBarCallIn(data) {
      let day = [];
      let answerCount = [];
      let answerLostCount = [];
      let answer15Count = [];
      let date = "";
      data.forEach((p) => {
        date = p.ndate;
        day.push(`${p.ntime}时`);
        answerCount.push(p.ncallInAnswer);
        answerLostCount.push(p.ncallInLost);
        answer15Count.push(p.ncallInAnswer15);
      });
      date = moment(date, "YYYYMMDD").format("YYYY年MM月DD日");
      let option = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          textStyle: {
            color: "#fff",
          },
          data: ["接听数", "未接听数", "15s接听数"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          axisLabel: {
            textStyle: {
              color: "#fff",
            },
          },
          type: "category",
          data: day,
        },
        yAxis: {
          axisLabel: {
            textStyle: {
              color: "#fff",
            },
          },
          type: "value",
        },
        series: [
          {
            name: "接听数",
            type: "bar",
            data: answerCount,
            color: "green",
            stack: "callIn",
          },
          {
            name: "未接听数",
            type: "bar",
            data: answerLostCount,
            color: "red",
            stack: "callIn",
          },
          {
            name: "15s接听数",
            type: "bar",
            data: answer15Count,
            color: "orange",
            stack: "callIn",
          },
        ],
      };
      //基于准本好的DOM，初始化echarts实例
      this.callInChart = this.$echarts.init(document.getElementById("callInChart"));
      //绘制图表
      this.callInChart.setOption(option);
    },
    drawBarCallOut(data) {
      let day = [];
      let answerCount = [];
      let answerLostCount = [];
      let answer15Count = [];
      let date = "";
      data.forEach((p) => {
        date = p.ndate;
        day.push(`${p.ntime}时`);
        answerCount.push(p.ncallOutAnswer);
        answerLostCount.push(p.ncallOutLost);
      });
      date = moment(date, "YYYYMMDD").format("YYYY年MM月DD日");
      let option = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          textStyle: {
            color: "#fff",
          },
          data: ["接听数", "未接听数"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          axisLabel: {
            textStyle: {
              color: "#fff",
            },
          },
          type: "category",
          data: day,
        },
        yAxis: {
          axisLabel: {
            textStyle: {
              color: "#fff",
            },
          },
          type: "value",
        },
        series: [
          {
            name: "接听数",
            type: "bar",
            data: answerCount,
            color: "green",
            stack: "callOut",
          },
          {
            name: "未接听数",
            type: "bar",
            data: answerLostCount,
            color: "red",
            stack: "callOut",
          },
        ],
      };
      //基于准本好的DOM，初始化echarts实例
      this.callOutChart = this.$echarts.init(document.getElementById("callOutChart"));
      //绘制图表
      this.callOutChart.setOption(option);
    },
    drawPieCallInAnswerPercent(data) {
      let option = {
        title: {
          text: "呼入话务量占比图",
          left: "center",
          textStyle: {
            color: "#fff",
          },
          top: 10,
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          bottom: "5%",
          left: "center",
          textStyle: {
            color: "#fff",
          },
        },
        color: ["green", "red"],
        series: [
          {
            type: "pie",
            radius: "50%",
            data: [
              { value: data.answer_in_count, name: "呼入接听" },
              { value: data.answer_in_lost_count, name: "呼入未接听" },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            label: {
              normal: {
                show: true,
                position: "inside",
                formatter: "{c}({d}%)",
              },
            },
          },
        ],
      };
      //基于准本好的DOM，初始化echarts实例
      this.callInAnswerPercentChart = this.$echarts.init(document.getElementById("callInAnswerPercentChart"));
      //绘制图表
      this.callInAnswerPercentChart.setOption(option);
    },
    drawPieCallOutAnswerPercent(data) {
      let option = {
        title: {
          text: "呼出话务量占比图",
          left: "center",
          textStyle: {
            color: "#fff",
          },
          top: 10,
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          bottom: "5%",
          left: "center",
          textStyle: {
            color: "#fff",
          },
        },
        color: ["green", "red"],
        series: [
          {
            type: "pie",
            radius: "50%",
            data: [
              { value: data.answer_out_count, name: "呼出接听" },
              { value: data.answer_out_lost_count, name: "呼出未接听" },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            label: {
              normal: {
                show: true,
                position: "inside",
                formatter: "{c}({d}%)",
              },
            },
          },
        ],
      };
      //基于准本好的DOM，初始化echarts实例
      this.callOutAnswerPercentChart = this.$echarts.init(document.getElementById("callOutAnswerPercentChart"));
      //绘制图表
      this.callOutAnswerPercentChart.setOption(option);
    },
    drawLineHistory(data) {
      let day = [];
      let ntotal = [];
      let ncallInTotal = [];
      let ncallOutTotal = [];
      let nabandon = [];
      let ncallInAnswer = [];
      let ncallInAnswerPercent = [];
      let ncallInLost = [];
      let ncallInLostPercent = [];
      let ncallInAnswer15 = [];
      let ncallInAnswerPercent15 = [];
      let ncallOutAnswer = [];
      let ncallOutAnswerPercent = [];
      let ncallOutLost = [];
      let ncallOutLostPercent = [];
      let date = "";
      data.forEach((p) => {
        date = p.ndate;
        if (this.historyQueryPara.type === 1) {
          day.push(`${p.ntime}时`);
        } else if (this.historyQueryPara.type === 2) {
          day.push(p.ndate.substring(4, 8));
        } else {
          day.push(`${p.ndate}`);
        }

        ntotal.push(p.ntotal);
        ncallInTotal.push(p.ncallInTotal);
        ncallOutTotal.push(p.ncallOutTotal);
        nabandon.push(p.nabandon);
        ncallInAnswer.push(p.ncallInAnswer);
        ncallInAnswerPercent.push(p.ncallInAnswerPercent);
        ncallInLost.push(p.ncallInLost);
        ncallInLostPercent.push(p.ncallInLostPercent);
        ncallInAnswer15.push(p.ncallInAnswer15);
        ncallInAnswerPercent15.push(p.ncallInAnswerPercent15);
        ncallOutAnswer.push(p.ncallOutAnswer);
        ncallOutAnswerPercent.push(p.ncallOutAnswerPercent);
        ncallOutLost.push(p.ncallOutLost);
        ncallOutLostPercent.push(p.ncallOutLostPercent);
      });
      let option = [];
      if (this.historyQueryPara.typeCount === 1) {
        date = moment(date, "YYYYMMDD").format("YYYY年MM月DD日");
        option = {
          tooltip: {
            trigger: "axis",
          },
          legend: {
            textStyle: {
              color: "#fff",
            },
            data: ["总话务量", "呼入话务量", "呼入未接听数", "呼出话务量", "排队用户放弃数"],
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            axisLabel: {
              textStyle: {
                color: "#fff",
              },
            },
            type: "category",
            data: day,
          },
          yAxis: {
            axisLabel: {
              textStyle: {
                color: "#fff",
              },
            },
            type: "value",
          },
          series: [
            {
              name: "总话务量",
              type: "line",
              data: ntotal,
              color: "blue",
            },
            {
              name: "呼入话务量",
              type: "line",
              data: ncallInTotal,
              color: "green",
            },
            {
              name: "呼入未接听数",
              type: "line",
              data: ncallInLost,
              color: "red",
            },
            {
              name: "呼出话务量",
              type: "line",
              data: ncallOutTotal,
              color: "yellow",
            },
            {
              name: "排队用户放弃数",
              type: "line",
              data: nabandon,
              color: "orange",
            },
          ],
        };
      } else if (this.historyQueryPara.typeCount === 2) {
        date = moment(date, "YYYYMMDD").format("YYYY年MM月DD日");
        option = {
          tooltip: {
            trigger: "axis",
          },
          legend: {
            textStyle: {
              color: "#fff",
            },
            data: ["呼入接听数", "呼入未接听数", "15s接听数"],
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            axisLabel: {
              textStyle: {
                color: "#fff",
              },
            },
            type: "category",
            data: day,
          },
          yAxis: {
            axisLabel: {
              textStyle: {
                color: "#fff",
              },
            },
            type: "value",
          },
          series: [
            {
              name: "呼入接听数",
              type: "line",
              data: ncallInAnswer,
              color: "green",
            },
            {
              name: "呼入未接听数",
              type: "line",
              data: ncallInLost,
              color: "red",
            },
            {
              name: "15s接听数",
              type: "line",
              data: ncallInAnswer15,
              color: "orange",
            },
          ],
        };
      } else if (this.historyQueryPara.typeCount === 3) {
        date = moment(date, "YYYYMMDD").format("YYYY年MM月DD日");
        option = {
          tooltip: {
            trigger: "axis",
          },
          legend: {
            textStyle: {
              color: "#fff",
            },
            data: ["呼出接听数", "呼出未接听数"],
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            axisLabel: {
              textStyle: {
                color: "#fff",
              },
            },
            type: "category",
            data: day,
          },
          yAxis: {
            axisLabel: {
              textStyle: {
                color: "#fff",
              },
            },
            type: "value",
          },
          series: [
            {
              name: "呼出接听数",
              type: "line",
              data: ncallOutAnswer,
              color: "green",
            },
            {
              name: "呼出未接听数",
              type: "line",
              data: ncallOutLost,
              color: "red",
            },
          ],
        };
      }
      //基于准本好的DOM，初始化echarts实例
      this.callHistoryChart = this.$echarts.init(document.getElementById("callHistoryChart"));
      //绘制图表
      this.callHistoryChart.setOption(option);
    },
    drawBarRecord(data) {
      let userName = [];
      let callTotal = [];
      let callAnswerTotal = [];
      let callAnswerLostTotal = [];
      let callAnswer15Total = [];
      let callLostTotal = [];
      data.forEach((p) => {
        userName.push(p.username);
        callTotal.push(p.call_total);
        callAnswerTotal.push(p.call_in_total);
        callAnswerLostTotal.push(p.call_in_lost_total);
        callAnswer15Total.push(p.call_in_answer_15);
        callLostTotal.push(p.call_out_total);
      });
      let option = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          textStyle: {
            color: "#fff",
          },
          data: ["呼入接听数", "呼入未接听数", "呼出接听数", "15s内接听数"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "20%",
          containLabel: true,
        },
        xAxis: {
          axisLabel: {
            textStyle: {
              color: "#fff",
            },
          },
          type: "category",
          data: userName,
        },
        yAxis: {
          axisLabel: {
            textStyle: {
              color: "#fff",
            },
          },

          axisTick: {
            alignWithLabel: true,
          },
          type: "value",
        },
        dataZoom: [
          {
            type: "slider",
            start: 0,
            end: 30,
            moveHandleSize: 3,
          },
          {
            start: 0,
            end: 30,
          },
        ],
        series: [
          {
            name: "呼入接听数",
            type: "bar",
            data: callAnswerTotal,
            color: "green",
            stack: "callOut",
          },
          {
            name: "呼入未接听数",
            type: "bar",
            data: callAnswerLostTotal,
            color: "red",
            stack: "callOut",
          },
          {
            name: "呼出接听数",
            type: "bar",
            data: callLostTotal,
            color: "yellow",
            stack: "callOut",
          },
          {
            name: "15s内接听数",
            type: "bar",
            data: callAnswer15Total,
            color: "orange",
            stack: "callOut",
          },
        ],
      };
      //基于准本好的DOM，初始化echarts实例
      this.recordDataChart = this.$echarts.init(document.getElementById("recordDataChart"));
      //绘制图表
      this.recordDataChart.setOption(option);
    },
    resize(data) {
      document.getElementById("callInChart").style.height = "0px";
      document.getElementById("callOutChart").style.height = "0px";
      document.getElementById("callInChart").style.height = this.$refs.callIn.clientHeight - 40 + "px";
      document.getElementById("callInChart").style.width = this.$refs.callIn.clientWidth + "px";
      document.getElementById("callOutChart").style.height = this.$refs.callOut.clientHeight - 40 + "px";
      document.getElementById("callOutChart").style.width = this.$refs.callOut.clientWidth + "px";
      if (this.callOutChart) {
        this.callOutChart.dispose();
      }
      if (this.callInChart) {
        this.callInChart.dispose();
      }
      this.drawBarCallIn(data);
      this.drawBarCallOut(data);
    },
    resizePie(data) {
      document.getElementById("callInAnswerPercentChart").style.height = "0px";
      document.getElementById("callOutAnswerPercentChart").style.height = "0px";
      document.getElementById("callInAnswerPercentChart").style.height = this.$refs.callTotalPie.clientHeight - 40 + "px";
      document.getElementById("callInAnswerPercentChart").style.width = this.$refs.callTotalPie.clientWidth / 2 + "px";
      document.getElementById("callOutAnswerPercentChart").style.height = this.$refs.callTotalPie.clientHeight - 40 + "px";
      document.getElementById("callOutAnswerPercentChart").style.width = this.$refs.callTotalPie.clientWidth / 2 + "px";
      if (this.callInAnswerPercentChart) {
        this.callInAnswerPercentChart.dispose();
      }
      if (this.callOutAnswerPercentChart) {
        this.callOutAnswerPercentChart.dispose();
      }
      this.drawPieCallInAnswerPercent(data);
      this.drawPieCallOutAnswerPercent(data);
    },
    resizeHistory(data) {
      document.getElementById("callHistoryChart").style.height = "0px";
      document.getElementById("callHistoryChart").style.height = this.$refs.callHistoryChart.clientHeight - 50 + "px";
      document.getElementById("callHistoryChart").style.width = this.$refs.callHistoryChart.clientWidth + "px";

      if (this.callHistoryChart) {
        this.callHistoryChart.dispose();
      }
      this.drawLineHistory(data);
    },
    resizeRecord(data) {
      console.log(data);
      document.getElementById("recordDataChart").style.height = "0px";
      document.getElementById("recordDataChart").style.height = this.$refs.recordDataChart.clientHeight - 40 + "px";
      document.getElementById("recordDataChart").style.width = this.$refs.recordDataChart.clientWidth + "px";

      if (this.recordDataChart) {
        this.recordDataChart.dispose();
      }
      this.drawBarRecord(data);
    },
    openHistoryDialogHandle() {
      this.historyDialog = true;
      this.resetPara();
    },
    historyDialogHandleClose() {
      this.historyDialog = false;
    },
    doSearch() {},
    resetPara() {
      this.historyQueryPara = {
        type: 1,
        typeCount: 1,
        startDate: moment().startOf("day").format("YYYY-MM-DD HH"),
        endDate: moment().startOf("day").format("YYYY-MM-DD HH"),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
//@import "../assets/scss/index.scss";

.scale-wrap {
  //width: 1920px;
  //height: 1080px;
  width: 100%;
  height: 100%;

  .bg {
    width: 100%;
    height: 100%;
    // padding: 16px 16px 0 16px;
    background-image: url("../../assets/pageBg.png");
    background-size: cover;
    background-position: center center;

    .headerWrapper {
      height: 90px;
      width: 100%;
      background-image: url("../../assets/header.png");
      background-size: cover;
      // background-position: center center;
      z-index: 10;

      & > div {
        padding-top: 38px;
      }

      .aside-width {
        flex: 1;
        justify-content: space-around;
      }

      .projectName {
        width: 300px;
        text-align: center;
        padding-top: 20px;
        margin: 0 90px;
        font-size: 30px;
        font-weight: bold;
        color: #fff;
        text-shadow: 5px 5px 5px 5px red;
      }

      .leftBox,
      .rightBox {
        min-width: 100px;
        padding: 14px 20px 6px;
        background-color: none;

        &.active {
          background-image: url("../../assets/tab_active.png");
          background-size: 100% 100%;
          background-position: center center;
        }
      }
    }
  }
}

.flex-column {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__top {
    width: 100%;
    color: white;
    background-image: url("../../assets/header.png");
    background-size: 100%;
    height: 85px;
    display: flex;
    flex-direction: row;

    .left,
    .right {
      flex: 1;
      line-height: 80px;
      padding: 0 10px;
    }

    .main {
      padding-top: 10px;
      width: 20%;
      text-align: center;
    }

    .font24 {
      font-size: 24px;
    }

    .font16 {
      font-size: 16px;
    }
  }

  &__main {
    flex: 1;
    display: flex;
    flex-direction: row;
    //height: 100%;
    overflow: hidden;

    .main1,
    .main3 {
      width: 33.33%;
      height: 100%;
    }

    .main2 {
      width: 33.33%;
      height: 100%;
    }

    .main1 {
      display: flex;
      flex-direction: column;
      flex: 1;
      height: 100%;

      .agent--status__container {
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        //margin-bottom: 10px;
        &__content {
          display: flex;
          flex-direction: row;
          width: 100%;
          flex: 1;
          overflow: hidden;
          //display: block;
          height: 100%;

          .content1 {
            //height: 100%;
            color: white;
            overflow: auto;

            div {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              width: 100%;
              text-align: center;
              padding: 0 1rem;
              margin: 10px 0;
            }
          }

          .content2 {
            //height: 100%;
            color: white;
            padding: 1rem 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            max-width: 200px;
            div {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              width: 100%;
              text-align: center;
              padding: 0 1rem;
            }
          }
        }
      }

      .callIn__container {
        margin: 10px;
        flex: 1;

        .callIn__content {
          width: 100%;
          //height: 100%;
        }
      }

      .callOut__container {
        margin: 10px;
        flex: 1;

        .callOut__content {
          width: 100%;
          //height: 100%;
        }
      }
    }

    .main2 {
      display: flex;
      flex-direction: column;

      .callMap__container {
        flex: 1;
      }

      .history__container {
        height: 33.33%;
        //padding: 10px;
        //flex: 1;
        .history__content {
          width: 100%;
        }
      }
    }

    .main3 {
      display: flex;
      flex-direction: column;
      height: 100%;

      .total-count__container {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;
        height: 100%;

        .total-count__content {
          display: flex;
          flex-direction: row;
          margin: 15px;
          height: 100%;
          font-size: 16px;
        }

        .total-count__item {
          flex: 1;
          height: 100%;
          color: white;

          background: url("../../assets/img_zs.png"), url("../../assets/img_ys.png"), url("../../assets/img_zx.png"), url("../../assets/img_yx.png");
          background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
          background-position: 0 0, 100% 0, 0 100%, 100% 100%;
          margin-right: 10px;
          text-align: center;
          padding: 10px;
        }

        .total-count__item:last-child {
          margin-right: 0px;
        }
        .record__container {
          //flex: 1;
        }
        .record__content {
          width: 100%;
        }
      }

      .callTotalPie {
        display: flex;
        flex-direction: row;
        height: 100%;
        width: 100%;
        flex: 1;

        & > div {
          text-align: center;
          width: 100%;
          height: 100%;
        }
      }
    }

    .title {
      width: 100%;
      height: 40px;
      background-image: url("../../assets/contentTopRightAngleIcon.png");
      background-size: 100% 100%;
      margin-left: 1rem;
      color: white;

      & > span:first-child {
        line-height: 40px;
        margin-left: 3rem;
      }
    }

    .content {
      margin-left: 1rem;
      margin-right: 0.5rem;
      margin-top: 1rem;
      border: 1px solid #409eff;
      border-radius: 10px;
      width: 100%;
    }

    .circle-green {
      width: 10px; /* 圆点的宽度 */
      height: 10px; /* 圆点的高度 */
      background-color: #00ff00; /* 圆点的颜色 */
      border-radius: 50%; /* 将正方形变成圆形 */
      display: inline-block; /* 使圆点的显示为行内块元素 */
      margin-right: 5px;
    }

    .circle-red {
      width: 10px; /* 圆点的宽度 */
      height: 10px; /* 圆点的高度 */
      background-color: #ff0000; /* 圆点的颜色 */
      border-radius: 50%; /* 将正方形变成圆形 */
      display: inline-block; /* 使圆点的显示为行内块元素 */
      margin-right: 5px;
    }

    .circle-orange {
      width: 10px; /* 圆点的宽度 */
      height: 10px; /* 圆点的高度 */
      background-color: orange; /* 圆点的颜色 */
      border-radius: 50%; /* 将正方形变成圆形 */
      display: inline-block; /* 使圆点的显示为行内块元素 */
      margin-right: 5px;
    }

    .circle-gray {
      width: 10px; /* 圆点的宽度 */
      height: 10px; /* 圆点的高度 */
      background-color: gray; /* 圆点的颜色 */
      border-radius: 50%; /* 将正方形变成圆形 */
      display: inline-block; /* 使圆点的显示为行内块元素 */
      margin-right: 5px;
    }

    .circle-blue {
      width: 10px; /* 圆点的宽度 */
      height: 10px; /* 圆点的高度 */
      background-color: blue; /* 圆点的颜色 */
      border-radius: 50%; /* 将正方形变成圆形 */
      display: inline-block; /* 使圆点的显示为行内块元素 */
      margin-right: 5px;
    }
  }

  .amap-container {
    background-image: none !important;
    background-color: rgba(0, 0, 0, 0) !important;
  }
}
</style>

<style lang="scss">
.custom-progress-text .el-progress__text {
  font-size: 16px !important; /* 调整字体大小 */
}

.scale-wrap {
  .el-dialog__footer,
  .dialog-footer {
    text-align: center;
    display: inline-block;
    width: 100% !important;
  }
}

.amap-container {
  background-image: none !important;
  background-color: rgba(0, 0, 0, 0) !important;
}
</style>
